import {
  EventFeatureConfig,
  FeatureFlagKey,
  FeatureLimit,
  FeatureLimitKey,
  OrganizationFeatureConfig,
  PricingPlanEventFeatureConfig,
  featureFlagKeys,
} from './feature-config';

/**
 * @description For keys generation only
 */
const defaultFeatureLimit: Readonly<Required<FeatureLimit>> = {
  extraEntryTypes: [],
  maxEventDurationInHour: null,
  maxNumberOfAttendees: 0,
  maxNumberOfEventManagers: 0,
  maxNumberOfEventRegistrants: null,
  maxNumberOfNetworkingLoungeSeatsPerTable: 0,
  maxNumberOfNetworkingLoungeTables: 0,
  maxVideoLibraryFileSizeLimitInMb: 0,
  webinar3rdPartyIntegrations: [],
  liveStreamingDisabledTicketIds: [],
  wltMaxStreamingQuality: '480p',
  wltRecordingQuality: '480p',
  checkInAppDataSyncInterval: 20000,
  attendeeTagsFieldId: null,
  customAutomatedEmailSender: null,
  aiEngineTDCRecommendationURL: null,
  maxNumberOfOrgBooths: null,
  maxNumberOfOrgEventRegistrants: null,
  maxNumberOfOrganizationManagers: 0,
  maxNumberOfBulkUpdateAttendees: 1000,
  displayTenantDbHostRegion: null,
  displayTenantDbHostCluster: null,
};

const featureLimitKeys = Object.freeze(
  Object.keys(defaultFeatureLimit) as FeatureLimitKey[],
);

export const eventFeatureConfigKeys = [...featureFlagKeys, ...featureLimitKeys];

/**
 * @description For keys generation only
 */
const defaultPricingPlanEventFeatureConfig: Readonly<
  Required<PricingPlanEventFeatureConfig>
> = {
  // feature flags
  isAttendeeExplorerEnabled: false,
  isAttendeeExportingEnabled: false,
  isAttendeeGenerationEnabled: false,
  isAttendeeGenerationPaidEnabled: false,
  isAttendeeProfileCustomizationEnabled: false,
  isBoothEnabled: false,
  isBoothRepresentativeListEnabled: false,
  isCheckInAppEnabled: false,
  isConcurrentLoginDisabled: false,
  isContentI18nEnabled: false,
  isCsvImportEncodingDetectionDisabled: false,
  isEmailSendingEnabled: false,
  isEventPageCustomizationEnabled: false,
  isGamificationBadgeCollectionEnabled: false,
  isGeneralSearchEnabled: false,
  isInEventRealtimeDashboardEnabled: false,
  isInstantPollEnabled: false,
  isInstantQnAEnabled: false,
  isLeadCaptureBusinessCardEnabled: false,
  isLobbyEnabled: false,
  isMagicLinkEnabled: false,
  isMeetupAlwaysOnEnabled: false,
  isMultipleTrackSessionEnabled: false,
  isOverrideBoothContentConfigEnabled: false,
  isProductCatalogEnabled: false,
  isProductVideoWatermarkEnabled: false,
  isRealTimeInEventAnalyticsEnabled: false,
  isRegisteredAttendeesOnPeopleListEnabled: false,
  isSpeakerPageEnabled: false,
  isSpeedNetworkingEnabled: false,
  isSponsorEnabled: false,
  isVenueCustomizationEnabled: false,
  isWebhookEnabled: false,
  isWebinarAuditoriumEnabled: false,
  isWebinarOnDemandSessionEnabled: false,
  isWltHighQualityAudioEnabled: false,
  isWltReplayEnabled: false,
  isZendeskOnPortalSideEnabled: false,
  isWhiteLabelEnabled: false,
  isSkipEmailForFreeOrderEnabled: false,
  isSkipApprovalMechanismForRSVPEnabled: false,
  isLeadFinderAIEmailEnabled: false,
  isLeadFinderAILookalikePersonaEnabled: false,
  isPersonalizationMergeTagEnabled: false,
  isWltEnabled: false,
  isManualEmailWarmupDomainEnabled: false,

  // feature limits
  extraEntryTypes: [],
  maxEventDurationInHour: null,
  wltRecordingQuality: '480p',
  wltMaxStreamingQuality: '480p',
  maxNumberOfAttendees: 0,
  maxNumberOfEventManagers: 0,
  maxNumberOfEventRegistrants: null,
  maxNumberOfNetworkingLoungeSeatsPerTable: 0,
  maxNumberOfNetworkingLoungeTables: 0,
  maxVideoLibraryFileSizeLimitInMb: 0,
  webinar3rdPartyIntegrations: [],
  liveStreamingDisabledTicketIds: [],
};

export const pricingPlanFeatureConfigKeys = Object.keys(
  defaultPricingPlanEventFeatureConfig,
) as Array<keyof PricingPlanEventFeatureConfig>;

// eslint-disable-next-line @typescript-eslint/ban-types
type EditableEventFeatureConfig = Omit<
  EventFeatureConfig,
  keyof OrganizationFeatureConfig
>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type EditableEventFeatureFlag = Omit<
  EditableEventFeatureConfig,
  FeatureLimitKey
>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type EditableEventFeatureLimit = Omit<
  EditableEventFeatureConfig,
  FeatureFlagKey
>;
